import { createSlice } from "@reduxjs/toolkit";
import { isEmpty, keyBy } from "lodash";
import { compose, filter, keyBy as keyByFp } from "lodash/fp";

import { updateCamerasSuccess } from "./reducers/update-cameras-success";
import { assignStreamDevice } from "./thunks/assign-stream-device";
import { createStream } from "./thunks/create-stream";
import { deletePointOfInterest } from "./thunks/delete-point-of-interest";
import { getCameraFovImage } from "./thunks/get-camera-fov-image";
import { getCameraModule } from "./thunks/get-camera-module";
import { getCameraPoiNames } from "./thunks/get-camera-poi-names";
import { getCameraScans } from "./thunks/get-camera-scans";
import { getCameras } from "./thunks/get-cameras";
import { getCapabilityClass } from "./thunks/get-capability-class";
import { getLatestAlarmsPerDevice } from "./thunks/get-latest-alarms-per-device";
import { getLatestAlarmsPerStream } from "./thunks/get-latest-alarms-per-stream";
import { getStreams } from "./thunks/get-streams";
import { removeSerialNumber } from "./thunks/remove-serial-number";
import { replaceStreamDevice } from "./thunks/replace-stream-device";
import { retakePanorama } from "./thunks/retake-panorama";
import { retakePointOfInterestPreview } from "./thunks/retake-point-of-interest-preview";
import { setPanZeroOffset } from "./thunks/set-pan-zero-offset";
import { setPointOfInterestName } from "./thunks/set-point-of-interest-name";
import { setPointsOfInterestConfig } from "./thunks/set-points-of-interest-config";
import { setSerialNumber } from "./thunks/set-serial-number";
import { unassignStreamDevice } from "./thunks/unassign-stream-device";
import { updateCameraAttributesByDeviceId } from "./thunks/update-camera-attributes-by-device-id";
import { updateCameraProperties } from "./thunks/update-camera-properties";
import {
  filterCamerasByOrganization,
  getLatestAlarmsByDeviceId,
  updatedCameraWithSerialNumber
} from "./utils";

const initialState = {
  selectedCalendarDay: new Date(),
  capabilityClasses: {},
  cameras: [],
  scans: [],
  selectedOrgCameras: [],
  normalizedPoiNames: {},
  normalizedCameras: {},
  normalizedLatestAlarms: {},
  selected: "",
  reported: [],
  loading: false, // loading cameras
  isLoadingCameraEvents: false, // loading camera events
  error: { error: false },
  fovImages: {
    byDeviceId: {}
  },
  modules: {
    CameraApp: {
      ids: [],
      entities: {}
    }
  },
  selectedCameraPoiNames: {},
  streams: {
    byDeviceId: {},
    byId: {},
    streamIds: [],
    activeStreamIds: [],
    loading: false
  }
};

const updateStateOnError = (state, action) => {
  const error = {
    error: true,
    errorOn: action?.payload?.errorOn || action?.error
  };

  state.error = error;
};

const slice = createSlice({
  name: "cameras",
  initialState,
  reducers: {
    updateCamerasSuccess,
    selectCamera: (state, action) => {
      state.selected = action.payload.deviceId;
      state.scans = [];
    },
    setSelectedCalendarDay: (state, action) => {
      state.selectedCalendarDay = action.payload;
      state.scans = [];
    },
    cameraActionFailure: state => {
      state.loading = false;
    },
    addLatestAlarm: (state, action) => {
      state.normalizedLatestAlarms = getLatestAlarmsByDeviceId(action.payload);
    },
    setPoiNames: (state, action) => {
      state.normalizedPoiNames = action.payload;
    },
    setIsLoadingCameraEvents: (state, action) => {
      state.isLoadingCameraEvents = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getCameraScans.fulfilled, (state, action) => {
        state.loading = false;
        state.scans = action.payload.data;
      })
      .addCase(getCameraScans.pending, state => {
        state.loading = true;
      })
      .addCase(getCameraScans.rejected, (state, action) => {
        updateStateOnError(state, action);
        state.loading = false;
      })
      .addCase(deletePointOfInterest.fulfilled, (state, action) => {
        const { deviceId, position, panCenters } = action.payload;

        state.modules.CameraApp.entities[deviceId]["poi_config"][
          "pan_centers"
        ] = panCenters;
        delete state.fovImages.byDeviceId[deviceId][position];
      })

      .addCase(getCameraFovImage.fulfilled, (state, action) => {
        const { deviceId, fovImage, poiDegrees } = action.payload;
        const imageKey = poiDegrees ?? "pano";

        state.fovImages.byDeviceId[deviceId] = {
          ...state.fovImages.byDeviceId[deviceId],
          [imageKey]: fovImage
        };
      })

      .addCase(getCameras.pending, state => {
        state.loading = true;
      })

      .addCase(getCameras.fulfilled, (state, action) => {
        const { orgId, cameras, organizations } = action.payload;

        state.loading = false;
        state.cameras = cameras;
        state.normalizedCameras = cameras.reduce((acc, curr) => {
          acc[curr.deviceId] = curr;
          return acc;
        }, {});

        const selectedOrgCameras = filterCamerasByOrganization(
          cameras,
          organizations,
          orgId
        );
        state.selectedOrgCameras = selectedOrgCameras;
      })

      .addCase(getCameras.rejected, (state, action) => {
        updateStateOnError(state, action);
        state.loading = false;
      })

      .addCase(getCameraModule.fulfilled, (state, action) => {
        const { deviceId, ...data } = action.payload;

        state.modules.CameraApp.entities[deviceId] = data;
        state.modules.CameraApp.ids = [
          ...new Set([...state.modules.CameraApp.ids, deviceId])
        ];
      })

      .addCase(getCameraModule.rejected, (state, action) => {
        updateStateOnError(state, action);
        state.loading = false;
      })

      .addCase(getCapabilityClass.fulfilled, (state, action) => {
        const { deviceId, capabilityClass } = action.payload;
        state.capabilityClasses[deviceId] = { capabilityClass };
      })

      .addCase(getCapabilityClass.rejected, state => {
        state.loading = false;
      })

      .addCase(getCameraPoiNames.fulfilled, (state, action) => {
        const { poiNames } = action.payload;
        state.selectedCameraPoiNames = keyBy(poiNames, "poiId");
      })

      .addCase(retakePanorama.fulfilled, (state, action) => {
        const { deviceId, fovImage } = action.payload;
        state.fovImages.byDeviceId[deviceId].pano = fovImage;
      })

      .addCase(retakePointOfInterestPreview.fulfilled, (state, action) => {
        const { deviceId, fovImage, position } = action.payload;
        state.fovImages.byDeviceId[deviceId] = {
          ...state.fovImages.byDeviceId[deviceId],
          [position]: fovImage
        };
      })

      .addCase(setPanZeroOffset.fulfilled, (state, action) => {
        const { deviceId, offset } = action.payload;
        state.modules.CameraApp.entities[deviceId]["pan_zero_offset"] = offset;
      })

      .addCase(setPointOfInterestName.fulfilled, (state, action) => {
        const { poi } = action.payload;
        state.selectedCameraPoiNames[poi.poi] = poi;
      })

      .addCase(setPointsOfInterestConfig.fulfilled, (state, action) => {
        const { deviceId, panCenters } = action.payload;
        state.modules.CameraApp.entities[deviceId]["poi_config"][
          "pan_centers"
        ] = panCenters;
      })

      .addCase(getLatestAlarmsPerDevice.pending, state => {
        state.isLoadingCameraEvents = true;
      })

      .addCase(getLatestAlarmsPerDevice.fulfilled, (state, action) => {
        state.normalizedLatestAlarms = getLatestAlarmsByDeviceId(
          action.payload?.data
        );
        state.isLoadingCameraEvents = false;
      })

      .addCase(getLatestAlarmsPerDevice.rejected, (state, action) => {
        updateStateOnError(state, action);
        state.isLoadingCameraEvents = false;
      })

      .addCase(getLatestAlarmsPerStream.pending, state => {
        state.isLoadingCameraEvents = true;
      })

      .addCase(getLatestAlarmsPerStream.fulfilled, (state, action) => {
        state.normalizedLatestAlarms = getLatestAlarmsByDeviceId(
          action.payload?.data
        );
        state.isLoadingCameraEvents = false;
      })

      .addCase(getLatestAlarmsPerStream.rejected, (state, action) => {
        updateStateOnError(state, action);
        state.isLoadingCameraEvents = false;
      })

      .addCase(removeSerialNumber.fulfilled, (state, action) => {
        return updatedCameraWithSerialNumber(state, action.payload);
      })

      .addCase(setSerialNumber.fulfilled, (state, action) => {
        return updatedCameraWithSerialNumber(state, action.payload);
      })

      .addCase(updateCameraAttributesByDeviceId.pending, state => {
        state.loading = true;
      })
      .addCase(updateCameraAttributesByDeviceId.rejected, (state, action) => {
        updateStateOnError(state, action);
        state.loading = false;
      })
      .addCase(updateCameraAttributesByDeviceId.fulfilled, (state, action) => {
        const { name, device, assetId, archived, location } =
          action.payload.tags;
        const cameraIndex = state.cameras.findIndex(
          camera => device === camera.deviceId
        );
        const selectedOrgIndex = state.cameras.findIndex(
          camera => device === camera.deviceId
        );
        const camera = {
          ...state.cameras[cameraIndex],
          name,
          assetId,
          location,
          archived: archived ?? null
        };

        state.cameras[cameraIndex] = camera;
        state.normalizedCameras[device] = camera;
        state.selectedOrgCameras[selectedOrgIndex] = camera;
        state.loading = false;
      })

      .addCase(assignStreamDevice.fulfilled, (state, action) => {
        const { stream } = action.payload;
        const activeStreamIds = new Set(state.streams.activeStreamIds);

        state.streams.byId[stream.id] = stream;
        state.streams.byDeviceId[stream.deviceId] = stream;

        activeStreamIds.add(stream.id);
        state.streams.activeStreamIds = Array.from(activeStreamIds);
      })

      .addCase(createStream.fulfilled, (state, action) => {
        const { stream } = action.payload;

        state.streams.byId[stream.id] = stream;
        state.streams.streamIds.push(stream.id);

        if (!isEmpty(stream.deviceId)) {
          state.streams.byDeviceId[stream.deviceId] = stream;
          state.streams.activeStreamIds.push(stream.id);
        }
      })

      .addCase(replaceStreamDevice.fulfilled, (state, action) => {
        const { stream } = action.payload;
        const { deviceId: previousDeviceId } = state.streams.byId[stream.id];
        const activeStreamIds = new Set(state.streams.activeStreamIds);

        state.streams.byId[stream.id] = stream;
        state.streams.byDeviceId[stream.deviceId] = stream;

        delete state.streams.byDeviceId[previousDeviceId];
        activeStreamIds.add(stream.id);
        state.streams.activeStreamIds = Array.from(activeStreamIds);
      })

      .addCase(unassignStreamDevice.fulfilled, (state, action) => {
        const { stream } = action.payload;
        const activeStreamIds = new Set(state.streams.activeStreamIds);

        state.streams.byId[stream.id] = stream;

        delete state.streams.byDeviceId[stream.deviceId];
        activeStreamIds.delete(stream.id);
        state.streams.activeStreamIds = Array.from(activeStreamIds);
      })

      .addCase(getStreams.fulfilled, (state, action) => {
        const { streams } = action.payload;

        const keyByDeviceId = compose(
          keyByFp("deviceId"),
          filter(stream => stream.deviceId)
        );

        state.streams.byId = keyBy(streams, "id");
        state.streams.byDeviceId = keyByDeviceId(streams);
        state.streams.streamIds = streams.map(stream => stream.id);
        state.streams.activeStreamIds = streams
          .filter(stream => stream.deviceId)
          .map(stream => stream.id);

        state.streams.loading = false;
      })

      .addCase(getStreams.rejected, (state, action) => {
        updateStateOnError(state, action);
        state.streams.loading = false;
      })

      .addCase(updateCameraProperties.fulfilled, (state, action) => {
        const { deviceId, name, timezone, latitude, longitude, archived } =
          action.payload;

        for (let i = 0; i < state.cameras.length; i++) {
          if (state.cameras[i].deviceId === deviceId) {
            state.cameras[i] = {
              ...state.cameras[i],
              ...(name != null ? { name } : {}),
              ...(timezone != null ? { timezone } : {}),
              ...(archived != null ? { archived } : {}),
              location: {
                ...state.cameras[i].location,
                ...(latitude != null ? { latitude } : {}),
                ...(longitude != null ? { longitude } : {})
              }
            };
            break;
          }
        }

        state.normalizedCameras = {
          ...state.normalizedCameras,
          [deviceId]: {
            ...state.normalizedCameras[deviceId],
            ...(name != null ? { name } : {}),
            ...(timezone != null ? { timezone } : {}),
            ...(archived != null ? { archived } : {}),
            location: {
              ...state.normalizedCameras[deviceId]?.location,
              ...(latitude != null ? { latitude } : {}),
              ...(longitude != null ? { longitude } : {})
            }
          }
        };
      });
  }
});

export const CameraActions = {
  ...slice.actions,
  assignStreamDevice,
  deletePointOfInterest,
  createStream,
  getCameraFovImage,
  getCameraModule,
  getCameraPoiNames,
  getCameras,
  getCapabilityClass,
  getLatestAlarmsPerDevice,
  getLatestAlarmsPerStream,
  getStreams,
  retakePanorama,
  retakePointOfInterestPreview,
  removeSerialNumber,
  replaceStreamDevice,
  setPanZeroOffset,
  setPointOfInterestName,
  setPointsOfInterestConfig,
  setSerialNumber,
  unassignStreamDevice,
  updateCameraAttributesByDeviceId,
  getCameraScans,
  updateCameraProperties
};

export const CamerasReducer = slice.reducer;
