import axios from "axios";
import Cookies from "js-cookie";

import ApiManager from "./ApiManager";

class BaseApi {
  constructor(baseURL, timeout = 30000) {
    this.baseTime = new Date();

    this.axiosInstance = axios.create({
      baseURL,
      timeout
    });
    this.isRefreshing = false;
    this.refreshSubscribers = [];
    this.addRequestInterceptor();
    this.addResponseInterceptor();
  }
  static instance = null;
  static Instance() {
    if (!this.instance) {
      this.instance = new this();
    }
    return this.instance;
  }

  onRefreshed(token) {
    this.refreshSubscribers.forEach(callback => callback(token));
    this.refreshSubscribers = [];
  }

  addRefreshSubscriber(callback) {
    if (!this.refreshSubscribers) {
      this.refreshSubscribers = [];
    }
    this.refreshSubscribers.push(callback);
  }

  addRequestInterceptor() {
    this.axiosInstance.interceptors.request.use(
      config => {
        const isRefreshTokenTimedOut = !Cookies.get("kuva.auth.refresh.token");
        if (isRefreshTokenTimedOut) ApiManager.logout();

        const token = Cookies.get("kuva.auth.jwt");
        if (token) config.headers["Authorization"] = `Bearer ${token}`;

        return config;
      },
      error => Promise.reject(error)
    );
  }

  addResponseInterceptor() {
    this.axiosInstance.interceptors.response.use(
      response => response,
      async error => {
        if (error.response && error.response.status === 401) {
          if (this.isRefreshing) {
            return new Promise(resolve => {
              this.addRefreshSubscriber(token => {
                error.config.headers["Authorization"] = `Bearer ${token}`;
                resolve(this.axiosInstance.request(error.config));
              });
            });
          }

          this.isRefreshing = true;
          try {
            const accessToken = await ApiManager.getAccessTokenSilently();
            Cookies.set("kuva.auth.jwt", accessToken);

            this.onRefreshed(accessToken);
            this.isRefreshing = false;

            error.config.headers["Authorization"] = `Bearer ${accessToken}`;
            return this.axiosInstance.request(error.config);
          } catch (tokenError) {
            console.error("Error refreshing token:", tokenError);

            this.isRefreshing = false;
            this.refreshSubscribers.forEach(callback =>
              callback(Promise.reject(error))
            );
            this.refreshSubscribers = [];
            ApiManager.logout();
          }
        }

        return Promise.reject(error);
      }
    );
  }
}

export default BaseApi;
